import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteumuarama.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '4128104',
  schema: 'pbi_dte_umuarama',
  groupId: '5f2c1f8a-75fc-45a2-9464-76e8da33ac97',
  analiseGeral: '18ce1101-a7e1-4d5f-9892-1b0a8ac1e31e',
  analiseUsuarios: 'b0cfdb76-7b13-4f44-8287-2765f872a86c',
  analiseComunicados: '19a38624-5388-4779-8bf2-c836fdbd6578',
  analiseProcurador: '',
  analiseEventos: '2a95e399-0f91-450f-84b2-1c6990b4513b'
};
